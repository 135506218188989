import React from "react"
import kebabCase from "lodash/kebabCase"
import { Link, graphql } from "gatsby"
import Layout from "../components/layout"
import styles from "../scss/blog.module.scss"
import Seo from "../components/SEO"


const TagsPage = ({
  data: {
    allMarkdownRemark: { group },
    site: {
      siteMetadata: { title },
    },
  },
}) => (
  <Layout>
    <Seo />
    <div className="blog-list">
      <h1>
        Tags
      </h1>    
        <ul>
          <div className={styles.meta}>
                {group.map(tag => (
                  <li key={tag.fieldValue}> 
                    <Link to={`/tags/${kebabCase(tag.fieldValue)}/`}>
                    <button className="btn"> {tag.fieldValue} ({tag.totalCount})</button>
                    </Link>
                  </li>
                ))}
          </div>
         </ul>        
      </div>
      <main className="btn-wrapper">
            <Link to="/" >
              <button className="btn">
              Back 
              </button>
            </Link>
     </main>
</Layout>
)

export default TagsPage

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(limit: 2000) {
      group(field: frontmatter___tags) {
        fieldValue
        totalCount
      }
    }
  }
`